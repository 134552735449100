"use client";
import React from "react";
import { useTheme } from "@guberno/utils/theme";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { WcStack } from "@bpipartners/webui";

export const MainPageLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <WcStack
      id="MainPageLayoutInnerStack"
      direction="row"
      height="100%"
      width={"100%"}
      minWidth={theme.dimensions.minimumPageWidth}
      sx={{ overflowX: "auto", overflowY: "hidden" }}
    >
      {children}
    </WcStack>
  );
};
