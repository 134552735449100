import { useTheme as useThemeReact } from "@bpipartners/webui";
import { createTheme } from "@bpipartners/webui";
import { colors } from "./theme.colors";
import { dimensions } from "./dimensions";
import localFont from "next/font/local";

// Font files can be colocated inside of `app`
const PPPFormula = localFont({
  src: "./fonts/PPFormula-Medium.woff2",
  display: "swap",
});

export const getTheme = () => {
  const palette = colors.palette;
  const appColors = colors.appColors;
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line

  const materialTheme = createTheme({
    spacing: 2,
    components: {
      // MuiAutocomplete: {
      //   styleOverrides: {
      //     root: {
      //       "& .Mui-disabled": {
      //         background: "#f3f3f3",
      //         color: "#dadada",
      //       },
      //     },
      //   },
      // },
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderRadius: 12,
            padding: 0,
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: appColors.surface,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "medium",
        },
        styleOverrides: {
          root: {
            borderRadius: 24,
            height: 36,
            textTransform: "none",
            // background: colors.appColors.tertiary,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              backgroundColor: "inherit",
            },
            background: appColors.surfaceContainerLow,
            "&.Mui-disabled": {
              background: appColors.surfaceContainerLow,
              color: "#dadada",
            },
          },
        },
      },
    },
    palette,
    typography: {
      fontFamily: PPPFormula.style.fontFamily,
      fontSize: 12,
      h2: {
        fontSize: "1.9rem",
      },
      h3: {
        fontSize: "1.7rem",
      },
      h4: {
        fontSize: "1.5rem",
      },
      h5: {
        fontSize: "1.3rem",
      },
      h6: {
        fontSize: "1.1rem",
      },
    },
  });
  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors: colors.appColors,
    appPalette: colors.appPalette,
    dimensions,
  };
};

export type Theme = ReturnType<typeof getTheme>;

export const useTheme = () => useThemeReact() as Theme;
