import { AppColors, AppPalette, CustomPalette } from "@bpipartners/webui";

const appColors: AppColors = {
  primary: "#6010AE",
  onPrimary: "#FFFFFFFF",
  primaryContainer: "#CBAFE9FF",
  onPrimaryContainer: "#1A0132FF",
  secondary: "#806C93FF",
  onSecondary: "#FFFFFFFF",
  secondaryContainer: "#D8CDE4FF",
  onSecondaryContainer: "#332B3BFF",
  tertiary: "#FF9E00FF",
  onTertiary: "#FFFFFFFF",
  tertiaryContainer: "#FFDA9EFF",
  onTertiaryContainer: "#4D2F00FF",
  error: "#BA1A1A",
  onError: "#FFFFFF",
  errorContainer: "#FFDAD6",
  onErrorContainer: "#410002",
  background: "#FEF7FFFF",
  onBackground: "#1D1B20FF",
  surface: "#FEF7FFFF",
  onSurface: "#262626FF",
  surfaceVariant: "#E7E0EB",
  onSurfaceVariant: "#5C5761FF",
  outline: "#717171FF",
  outlineVariant: "#CFCDD0FF",
  shadow: "#000000",
  scrim: "#000000",
  inverseSurface: "#322F35",
  inverseOnSurface: "#F5EFF7",
  inversePrimary: "#B68DDEFF",
  primaryFixed: "#CBAFE9FF",
  onPrimaryFixed: "#1A0132FF",
  primaryFixedDim: "#B68DDEFF",
  onPrimaryFixedVariant: "#4D0594FF",
  secondaryFixed: "#D8CDE4FF",
  onSecondaryFixed: "#1D192BFF",
  secondaryFixedDim: "#CDC2DB",
  onSecondaryFixedVariant: "#4B4358",
  tertiaryFixed: "#FFDA9EFF",
  onTertiaryFixed: "#4D2F00FF",
  tertiaryFixedDim: "#FFD185FF",
  onTertiaryFixedVariant: "#B26F00FF",
  surfaceDim: "#DED8E1FF",
  surfaceBright: "#FEF7FF",
  surfaceContainerLowest: "#FFFFFFFF",
  surfaceContainerLow: "#F7F2FAFF",
  surfaceContainer: "#F3EDF7FF",
  surfaceContainerHigh: "#ECE6F0FF",
  surfaceContainerHighest: "#E6E6E7FF",
  backdrop: "#000000",
  relation: { organization: "#0B79D0", person: "#C77700", tag: "#C51162" },
  planning: {
    // planningRowBackground: "#F4F5F4",
  },
  importance: {
    major: { fg: "#d82e18", bg: "#f2bdb4" },
    medium: { fg: "#db7c2e", bg: "#EAAF71" },
    normal: { fg: "#6b6f73", bg: "#edf1f4" },
    low: { fg: "#01a2fa", bg: "#c6e9ff" },
  },
  surfaceTint: "",
  timesheet: {
    green: "",
    orange: "",
  },
  customColors: {
    raportTableColors: {
      domain: "#e5d7f4",
      goal: "#f5effb",
      measurement: "#faf7fd",
    },
    measurementLevels: {
      1: "#d82e18",
      2: "#db7c2e",
      3: "#CCA400",
      4: "#8FB302",
      5: "#3D9901",
    },
  },
};

const appPalette: AppPalette = {
  primary: {
    0: "#000000FF",
    10: "#1A0132FF",
    20: "#340066FF",
    30: "#4D0594FF",
    40: "#6010AEFF",
    50: "#782CC3FF",
    60: "#9257CBFF",
    70: "#9968CAFF",
    80: "#B68DDEFF",
    90: "#CBAFE9FF",
    95: "#EDDBFDFF",
    99: "#FFFBFEFF",
    100: "#FFFFFFFF",
    "25": "",
    "35": "",
    "5": "",
    "98": "",
  },
  secondary: {
    0: "#000000FF",
    10: "#332B3BFF",
    20: "#4D4554FF",
    30: "#665973FF",
    40: "#806C93FF",
    50: "#9A82B0FF",
    60: "#B499CCFF",
    70: "#BFACD2FF",
    80: "#CCBDDBFF",
    90: "#D8CDE4FF",
    95: "#E6DBF0FF",
    99: "#FFFBFEFF",
    100: "#FFFFFFFF",
    "25": "",
    "35": "",
    "5": "",
    "98": "",
  },
  tertiary: {
    0: "#000000FF",
    10: "#4D2F00FF",
    20: "#804D00FF",
    30: "#B26F00FF",
    40: "#FF9E00FF",
    50: "#FFAC24FF",
    60: "#FFBA49FF",
    70: "#FFC86DFF",
    80: "#FFD185FF",
    90: "#FFDA9EFF",
    95: "#FFE3B6FF",
    99: "#FFF7EBFF",
    100: "#FFFFFFFF",
    "25": "",
    "35": "",
    "5": "",
    "98": "",
  },
  neutral: {
    0: "#07000EFF",
    5: "#0F0D13FF",
    // 6: "#141218FF",
    10: "#262626FF",
    // 12: "#211F26FF",
    // 15: "#2B2930FF",
    20: "#444047FF",
    // 22: "#36343BFF",
    25: "#3B383EFF",
    30: "#5C5761FF",
    35: "#5C5761FF",
    40: "#686669FF",
    50: "#717171FF",
    60: "#888888FF",
    70: "#B5B3B7FF",
    80: "#CFCDD0FF",
    // 87: "#DED8E1FF",
    90: "#E6E6E7FF",
    // 92: "#ECE6F0FF",
    // 94: "#F3EDF7FF",
    95: "#F7F7F7FF",
    98: "#F7F2FAFF",
    99: "#FFFBFFFF",
    100: "#FFFFFFFF",
  },
  neutralVariant: {
    0: "#000000",
    5: "#121017",
    10: "#1D1A22",
    // 15: "#28242D",
    20: "#322F37",
    25: "#3E3A43",
    30: "#49454E",
    35: "#55515A",
    40: "#615D66",
    50: "#7A757F",
    60: "#948F99",
    70: "#AFA9B4",
    80: "#CBC4CF",
    90: "#E7E0EB",
    95: "#F6EEFA",
    98: "#FEF7FF",
    99: "#FFFBFF",
    100: "#FFFFFF",
  },
  error: [
    "#000000",
    null,
    null,
    null,
    "#280001",
    "#2d0001",
    "#310001",
    null,
    null,
    null,
    "#410002",
    null,
    "#490002",
    null,
    null,
    null,
    null,
    "#5c0004",
    null,
    null,
    "#690005",
    null,
    "#710005",
    null,
    "#790006",
    "#7e0007",
    null,
    null,
    null,
    null,
    "#93000a",
    null,
    null,
    null,
    null,
    "#a80710",
    null,
    null,
    null,
    null,
    "#ba1a1a",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#de3730",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ff5449",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ff897d",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ffb4ab",
    null,
    null,
    null,
    null,
    null,
    null,
    "#ffcfc9",
    null,
    null,
    "#ffdad6",
    null,
    "#ffe2de",
    null,
    "#ffe9e6",
    "#ffedea",
    "#fff0ee",
    null,
    "#fff8f7",
    "#fffbff",
    "#ffffff",
  ],
};

export const colors: CustomPalette = {
  appColors,
  appPalette,
  palette: {
    // type: "light",
    background: {
      default: "#FFF",
      paper: appColors.surfaceContainerLow,
    },
    primary: {
      light: appColors.primary,
      main: appPalette.primary[60],
      dark: appColors.primary,
      contrastText: appColors.onPrimary,
    },
    secondary: {
      light: appColors.secondary,
      main: appPalette.secondary[60],
      dark: appColors.secondary,
      contrastText: appColors.onSecondary,
    },
  },
};

// menu color: secondaryContainer
// hover color:
// selected color:
